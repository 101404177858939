import {Helmet} from "react-helmet";

function Head() {
  return (
    <Helmet>
      
    <meta charSet="utf-8" />
    <title>Flurks by Stonetoss</title>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous"/> 
    {/* TODO
    
    ADD SOCIAL META TAGS */}
    </Helmet>

  );
}

export default Head;
