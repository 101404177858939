import './Banner.css';
var Title = 'https://i.postimg.cc/pLr6Pzj4/title-white-subtitle-black.png';

function Banner() {
    return (
      <div className="banner" id="banner"> 
        <img id="bannerimg" src={Title} />   
      </div>

    );
  }
  // display: flex;
  export default Banner;
  