import './Terms.css';

function Terms() {
  return (
    <div className="container" id="termy" background="black"> 
        <div className="row no-gutters text-center">
            <div className="col-12 col-lg-6 col-sm-12 col-md-8 mx-auto">
              <div className="termbox"> 
                <h3 className="termtext">By participating in this sale, you acknowledge that you are buying a 
                unique piece of art and not a financial instrument. We cannot and do not guarantee you 
                financial returns through ownership or trade of your NFT.
                  </h3>
                  </div>
            </div>
        </div>
    </div>
  );
}

export default Terms;
