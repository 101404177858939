import "./Bottompanel.css";
import { useEffect, useState } from "react";

function BottomPanel() {
  useEffect(() => {
    var acc = document.getElementsByClassName("accordion");
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        /* Toggle between adding and removing the "active" class,
            to highlight the button that controls the panel */
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  });
  return (
    <div className="container" id="between-gallery2">
      <div className="row no-gutters text-left">
        <div className="col-12 col-lg-6 col-lg-8 col-md-8 mx-auto">
          <h1>FAQ</h1>
          <button class="accordion">
            {" "}
            <h3>Is this an Official Stonetoss project?</h3>
          </button>
          <div class="panel">
            <p class="answer">You bet!</p>
          </div>
          <button class="accordion">
            <h3>Why would I want to buy an NFT?</h3>
          </button>
          <div class="panel">
            <p class="answer">
              Many of the reasons people have for buying traditional art also
              apply to the purchase of NFTs. For instance, people may buy
              physical art or NFTs to:{" "}
            </p>
            <ul>
              <li>1. Support the artist</li>
              <li>2. As a status symbol</li>
              <li>3. To collect items they have an interest in</li>
              <li>4. To appreciate its artistic merit</li>
              <li>
                5. To speculate on its value (note: We do not and we cannot give
                you expectations on the future value of stonetoss Flurks.)
              </li>
              <li>6. To enjoy some sweet perks (see below)</li>
            </ul>
          </div>
          <button class="accordion">
            <h3>What benefits are there to owning a Flurk NFT?</h3>
          </button>
          <div class="panel">
            <p class="answer">In addition to the reasons listed above, all Flurk holders can enjoy a 15% discount at <a href="https://dissidentsoaps.com/">DissidentSoaps.com</a> using the <a href="https://www.hotchocolate.finance/Home">HotChocolate desktop plugin for Chrome browser</a>.</p>
          </div>
          <button class="accordion">
            <h3>How do I see my NFTs on Metamask Mobile?</h3>
          </button>
          <div class="panel">
            <p class = "answer">One of the popular NFT wallets used is Metamask. Curiously, their reliance on OpenSea's poor API causes some collections not to immediately appear. Thankfully there is a solution:</p>
            <p class="answer">
              <img
                src="https://i.postimg.cc/SNDngqXL/metamask1.jpg"
                width="50%"
                alt="1"
              />
            </p>
            <p class="answer">Just click import NFTS</p>

            <p class="answer">
              <img
                src="https://i.postimg.cc/tTMr3839/metamask2.jpg"
                width="50%"
                alt="1"
              />
            </p>
            <p class="answer">
              Then paste the flurks contract address
              0xDe6B6090D32eB3eeae95453eD14358819Ea30d33 and your token ID
            </p>
            <p class = "answer">If you're unsure of what your token ID is you can use <a href="https://etherscan.io/">Etherscan</a>.

Simply lookup your wallet address and check your recent Erc721 transactions. One of them should include the token ID in a format like "Token ID [3539]", where the number listed is your token ID.</p>
          
          </div>
          <button class="accordion">
            <h3>How is this generative NFT project different from others?</h3>
          </button>
          <div class="panel">
            <p class="answer">
              Unique to stonetoss Flurks is their connection to a professional
              artist. You can check out the{" "}
              <a href="https://opensea.io/collection/stonetoss-expanded-universe">
                previous stonetoss NFT drop here
              </a>
              .
            </p>
            <p class="answer">
              Owning a Flurk is collecting a piece from one of the most
              recognizable art brands on the planet.
            </p>
            <p class="answer">
              Normally, you'd have to explain to your friends who most artists
              behind a typical NFT are, however, most artists aren't stonetoss.
            </p>
            <p class = "answer">As luck would have it, Flurks have the distinction of being one of the few, censored, high-profile NFT collections. After taking only <a href="https://news.bitcoin.com/political-cartoonist-accuses-nft-platforms-opensea-rarible-of-being-tools-for-political-censorship/">22 minutes to sell out nearly ~$2 million of NFTs, Flurks were famously censored</a> before becoming available on the pro-speech marketplace, Scatter.art. <a href="https://www.flurksnft.com/#">Grab your own edition of this illegal art today!</a></p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default BottomPanel;
