import './Hero.css';
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import Flurks from '../contracts/Flurks.json'
import detectEthereumProvider from '@metamask/detect-provider';
//import Sequence2 from '../images/Sequence_02.gif'
//import Title from '../images/title-white-subtitle-black.png';

const Hero = () => {    

    let web3;

    const [mintValue, setMintValue] = useState(1);
    const [accounts, setAccounts] = useState([]);
    const [flurks, setFlurk] = useState();
    const [remaining, setRemaining] = useState(undefined);
  
    const initWeb3 = () => {
      return new Promise( async (resolve, reject) => {
        let provider = await detectEthereumProvider();
        if(provider) {
          await provider.request({ method: 'eth_requestAccounts' });
          try {
            const web3 = new Web3(window.ethereum);
            resolve(web3);
          } catch(error) {
            reject(error);
          }
        } reject('Install Metamask');
      });
    };
    const handleAccountsChanged = (newAccount) => {
      console.log("Account changed.");
  
      if (newAccount[0] !== accounts) {
        setAccounts(newAccount);
      }
    };
    const initContract = () => {
      const deploymentKey = Object.keys(Flurks.networks)[0];
      return new web3.eth.Contract(
        Flurks.abi,
        Flurks.networks[deploymentKey].address
      );
    };

    const initApp = () => {
      web3.eth.getAccounts()
        .then(_accounts => {
          setAccounts(_accounts);
        });
    };
  
    useEffect(() => {
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", handleAccountsChanged);
      }
      initWeb3()
        .then((_web3) => {
          web3 = _web3;
          const contract = initContract();
          setFlurk(contract);
          initApp();
          // How Many Flurks
          const interval = setInterval(() => {
          contract.methods
            .flurkCount()
            .call()
            .then((x) => {
              setRemaining(4964 - x);
            })
            .catch((e) => console.log("U WOT M8", e));
          }, 1000);
          return () => clearInterval(interval);
        })
        .catch(e => console.log(e.message));
    }, []);
  
    const handleInputChange = (event) => {
      setMintValue(event.target.value);
    }
  
    const handleMintSubmit = (e) => {
      e.preventDefault();
      const $buyFrawgsResult = document.getElementById('buyFrawgs-result');
    //   const tokensNumber = mintValue;
      const tokensNumber = document.getElementById("mint-input").value;
      console.log(tokensNumber);
      if (tokensNumber > 10 || tokensNumber < 1) {
        $buyFrawgsResult.innerHTML = "Please enter a token number between 1 and 10.";
      } else {
        $buyFrawgsResult.innerHTML = `Generating your Flurk(s)`;
        flurks.methods.buyFlurks(tokensNumber).send({ from: accounts[0], value: 85000000000000000 * tokensNumber })
          .then(() => {
            $buyFrawgsResult.innerHTML = `You just minted ${tokensNumber} Flurk(s)!`;
          })
          .catch(_e => {
            $buyFrawgsResult.innerHTML = `Something went wrong`;
          });
      }
    }

  return (
<section id="hero" className="container-fluid d-flex align-items-center justify-content-center">
 
<div className="containery">

    <div className="row">
        <div className="col-12 col-lg-5">
            <img src={"https://i.postimg.cc/MT7s9DkM/05-sek-gif-DIFFERENT.gif"}/>
    
          </div>


          <div className="col-12 col-lg-7 mint-hero">
              <div className="row d-flex justify-content-center align-items-center no-gutters">
 <h1>Buy here:</h1>
              </div>           
              <div className="row  mint-row no-gutters"></div>
              <div className="row mint-row no-gutters">
                  <div className="col-12 col-md-12">
                  <p id="buyFrawgs-result"></p>
     

    

<a href="https://www.scatter.art/collection/0xde6b6090d32eb3eeae95453ed14358819ea30d33"><button enabled="" type="button" id="mint-button" className="mint-btn btn btn-primary btn-lg">Scatter.art</button></a>

                  </div>
              </div>
             
                  <div className="heroremaining">
                  
                  {!!remaining && `${remaining} Remaining`}
                    
                  </div>
       
            
        
          </div>
       
      </div>
   
  </div>
  
</section>

  );
};

export default Hero;



