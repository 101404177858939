import './ImageGallery.css';

var sample1 ="https://i.postimg.cc/xCMZWQmd/sample1.png";
var sample2 ="https://i.postimg.cc/QMRP4CHP/sample2.png";
var sample3 ="https://i.postimg.cc/MHwLwL0m/sample3.png";
var sample4 ="https://i.postimg.cc/brRBwR3c/sample4.png";
var sample5 ="https://i.postimg.cc/9FWkNvQW/sample5.png";
var sample6 ="https://i.postimg.cc/1R0TB9PC/sample6.png";
var sample7 ="https://i.postimg.cc/CLm2ymRb/sample7.png";
var sample8 ="https://i.postimg.cc/sfpFLYPJ/sample-handmade-1.png";
var sample9 ="https://i.postimg.cc/sDsFCMwC/sample-handmade-2.png";
var sample10 ="https://i.postimg.cc/hPrkDK5z/sample-handmade-3.png";
var sample11 ="https://i.postimg.cc/9QQvzs6x/sample-handmade-4.png";
var sample12 ="https://i.postimg.cc/Bv8kBHQH/sample-handmade-5.png";

const ImageGallery = (section) => {
    const imageDatabase = [
        [sample1,
        sample2,
        sample3,
        sample4,
        sample5,
        sample6],
        [sample7,
        sample8,
        sample9,
        sample10,
        sample11,
        sample12]
    ];
    let imagedata = imageDatabase[section['section']]; 

  return (
    <section className="container-fluid img-gallery">
    <div className="row no-gutters">
        <div className="col-2">
            <img src={imagedata[0]} alt="1"/>
        </div>
        <div className="col-2">
            <img src={imagedata[1]} alt="2"/>
        </div>
        <div className="col-2">
            <img src={imagedata[2]} alt="3"/>
        </div>
        <div className="col-2">
            <img src={imagedata[3]} alt="4"/>
        </div>
        <div className="col-2">
            <img src={imagedata[4]} alt="5"/>
        </div>
        <div className="col-2">
            <img src={imagedata[5]} alt="6"/>
        </div>
    </div>
</section>
  );
}

export default ImageGallery;
