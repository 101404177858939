import './About.css';
var Flurk1  = 'https://i.postimg.cc/Sx1H9Dqm/flurk1.png';
var Flurk3 ='https://i.postimg.cc/CKfrq0m4/flurk3.png';
var Flurk4 = 'https://i.postimg.cc/mgn6kkND/flurk4.png';
function About() {
  return (
    <div className="container" id="between-gallery" background="black"> 
        <div className="row no-gutters text-center">
            <div className="col-12 col-lg-6 col-sm-12 col-md-8 mx-auto">
              <div className="flurk2">
                <h1 >What is a Flurk?</h1>
                <img src={Flurk1} width="25%" />   
                <h3 className="flurky">Flurks (anagram of Klurf) are the humanoid beings that inhabit the world of <a href="http://stonetoss.com/" >stonetoss comics</a>. They are silly, playful, and sometimes absurd.
                <h3 className="flurky">They've been found everywhere in the world, from memes about <a href="http://stonetoss.com/comic/burger-kang/" > "Burgers?"</a> to even <a href="https://www.youtube.com/watch?v=5DlROhT8NgU" > Amogus</a>.
</h3>
There's no telling where else they will invade next - and now you can capture your very own!
                  </h3>
                  </div>
                  <div className="flurk1">
                  <img src={"https://i.postimg.cc/MT7s9DkM/05-sek-gif-DIFFERENT.gif"} width="40%" class="imgrounded"/>   
                   <h3 className="flurky" >There are THOUSANDS* of them. Each one is randomly generated with their own unique set of traits. You can collect, trade, sell, and store them in your crypto wallet.</h3>
                   <img src={Flurk3} width="85%" class="imgrounded" />   
<h3 className="flurky">Like humans, they have 2 genders but many sexualities. Flurks come in all variety of colors and personalities.</h3>

  <img src={Flurk4} width="75%" />   
<h3 className="flurky">Flurk hair, for instance, can be styled into nearly any shape.</h3>
  <a href="#" class="minty" ><h2>What sort of Flurk will yours turn out to be?</h2></a>
  </div>

            </div>
        </div>
    </div>
  );
}

export default About;
