import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Navbar from './Components/Navbar'
import Mint from './Components/mint';
import Header from './Components/header';
import Hero from './Components/Hero';
import Terms from './Components/Terms';
import ImageGallery from './Components/ImageGallery';
import Footer from './Components/Footer';
import Head from './Components/Head'
import About from './Components/About'
import './Components/bootstrap.min.css';
import BottomPanel from './Components/Bottompanel';
import Banner from './Components/Banner';

ReactDOM.render(
  <React.StrictMode>
   <Head />
   {/*  <Navbar />*/}
    <div className="app-banner">
    <Banner />
    </div>
    <div className="app-mint">
    <Hero />
    <Terms />
    </div>
    <ImageGallery section={0} />
    <About />
    <ImageGallery section={1} />
    {/* <Header /> */}
    {/* <Mint /> */}
    <BottomPanel />
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);
