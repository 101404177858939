import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
    <div className="container">
        <div className="row">
            <div className="col-12 col-md">
            < a className="mb-2" href="https://twitter.com/flurksnft"><i className="fab fa-twitter"></i></a>          
<a className="mb-2" href="https://t.me/Flurks" ><i className="fab fa-telegram"></i></a>
            </div>
          </div>
    </div>
  </footer>
  );
}

export default Footer;
